// Path: ./src/app/desktop-src/app/pages/LatestPage/index.tsx
import * as React from 'react';
import { GfFlyerDto } from '@swagger/typescript-fetch-goflyer';
import { FlyerCard } from 'app/desktop-src/app/components/FlyerCard/Loadable';
import { apiService } from 'utils/api';
import Grid from '@mui/material/Grid';
import InfiniteScroll from 'react-infinite-scroll-component';
import { RootState } from 'types';
import { useDispatch, useSelector } from 'react-redux';

import { checkIfStoreInFavList } from 'utils/dtoUtility';
import { useClickFavFunc } from 'app/desktop-src/app/common/ClickFavButtonFunc';
import { Spinner } from 'app/desktop-src/app/components/Spinner';
import { PageHeader } from 'app/desktop-src/app/components/PageHeader';
import { messages } from 'locales/messages';
import { useTranslation } from 'react-i18next';
import { useFlyerListSlice } from 'app/desktop-src/store/FlyerList';
import {
  FlyerPageContainer,
  FlyerPageFlyerLink,
  FlyerPageFlyerWrapper,
  FlyerPageNotFoundWrapper,
} from 'app/components/Common/FlyerPage';
import CustomHelmet from 'app/components/Helmet';

export const LatestPage = () => {
  const { t } = useTranslation();
  const { actions } = useFlyerListSlice();
  const { Latest } = useSelector((state: RootState) => state.flyerList);
  const [hasMore, setHasMore] = React.useState<boolean>(true);
  const [pageLoader, setPageLoader] = React.useState<boolean>(true);

  const clickFavButton = useClickFavFunc();
  const dispatch = useDispatch();
  const { customer }: RootState = useSelector<RootState, RootState>(
    state => state,
  );

  React.useEffect(() => {
    setTimeout(() => {
      if (window !== undefined) {
        const scrolValue = Latest?.scrollPosition;
        if (scrolValue) {
          document
            ?.getElementById('scrollableDiv')
            // @ts-ignore
            ?.scrollTo({ top: scrolValue, behavior: 'auto' });
          // @ts-ignore
          window.scrollTo({ top: scrolValue });
        }
      }
    }, 50);
  }, []);

  const getFlyers = async () => {
    try {
      setHasMore(false);
      const limit = 20;
      const FlyerResultList = await apiService.gfFlyerControllerFindAll(
        // @ts-ignore
        Latest.currentPage,
        limit,
        JSON.stringify({
          orderBy: {
            updatedAt: 'DESC',
          },
        }),
        undefined,
      );

      if (FlyerResultList?.results?.length !== 0) {
        dispatch(
          actions.addFlyerList({
            type: 'Latest',
            condition: 'PageNumer',
            // @ts-ignore
            data: Latest?.currentPage + 1,
          }),
        );
        setHasMore(true);
        const FlyerLatestResponse: GfFlyerDto[] =
          FlyerResultList?.results?.sort((a: GfFlyerDto, b: GfFlyerDto) => {
            if (a.stores[0]?.rank && !b.stores[0]?.rank) {
              return a.stores[0]?.rank;
            }
            if (b.stores[0]?.rank && !a.stores[0]?.rank) {
              return b.stores[0]?.rank;
            }
            if (b.stores[0]?.rank && a.stores[0]?.rank) {
              return a.stores[0]?.rank - b.stores[0]?.rank;
            }
            return 0;
          });
        // @ts-ignore
        if (Latest.results?.length) {
          dispatch(
            actions.addFlyerList({
              type: 'Latest',
              data: {
                results: [
                  // @ts-ignore
                  ...(Latest.results as GfFlyerDto[]),
                  ...FlyerLatestResponse,
                ],
              },
            }),
          );
        } else {
          dispatch(
            actions.addFlyerList({
              type: 'Latest',
              data: {
                results: [...FlyerLatestResponse],
              },
            }),
          );
        }
      }
      setPageLoader(false);
    } catch (error) {
      console.log('error', error);
    }
  };

  React.useEffect(() => {
    if (Latest?.results?.length) {
      getFlyers();
      setPageLoader(false);
    } else {
      getFlyers();
    }
  }, []);

  const handleScroll = event => {
    dispatch(
      actions.addFlyerList({
        type: 'Latest',
        condition: 'scrollPosition',
        data: event.currentTarget.scrollTop,
      }),
    );
  };
  return (
    <>
      <CustomHelmet
        title={`Goflyer - ${t(messages.Flyers())}`}
        description={t(messages.latestDescription())}
        keywords={t(messages.latestKeywords())}
      />
      <FlyerPageContainer>
        <PageHeader data-testid="Flyers" title={t(messages.Flyers())} />

        {pageLoader ? (
          <Spinner />
        ) : (
          // @ts-ignore
          Latest.results === undefined ||
          (Latest && Latest?.results.length <= 0 && (
            <FlyerPageNotFoundWrapper>
              <h1>
                {t(messages.THERE_ARE_NO_LATEST_FLYERS())} <br />{' '}
                {t(messages.Stay_Tuned())}{' '}
              </h1>
            </FlyerPageNotFoundWrapper>
          ))
        )}

        <FlyerPageFlyerWrapper id="scrollableDiv" onScroll={handleScroll}>
          <InfiniteScroll
            scrollThreshold={0.2}
            style={{
              paddingBottom: '50px',
              paddingLeft: '40px',
              paddingRight: '40px',
            }}
            next={getFlyers}
            // @ts-ignore
            dataLength={Latest.results?.length ? Latest.results.length : 20}
            hasMore={hasMore}
            loader={<Spinner />}
            scrollableTarget="scrollableDiv"
          >
            <Grid container spacing={2} data-testid="Flyer list Wrapper">
              {Latest?.results?.map((value: GfFlyerDto, key) => {
                const store_in_fav_list = checkIfStoreInFavList(
                  value?.stores[0],
                  customer?.gfStoreFavouriteList,
                );
                return (
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    lg={6}
                    key={key}
                    data-testid="latestpage-test-id"
                  >
                    <FlyerPageFlyerLink
                      to={{
                        pathname: `/flyerview/${
                          value.id
                        }-${value.stores[0].merchant.name
                          .replace(/\s+/g, '-')
                          .toLowerCase()}-flyer`,
                        state: {
                          FlyerDetails: JSON.stringify(value),
                        },
                      }}
                    >
                      <FlyerCard
                        data={value}
                        storeFavList={store_in_fav_list}
                        handleHeartClick={e => {
                          clickFavButton(
                            e,
                            value?.stores[0],
                            store_in_fav_list !== undefined,
                          );
                        }}
                      />
                    </FlyerPageFlyerLink>
                  </Grid>
                );
              })}
            </Grid>
          </InfiniteScroll>
        </FlyerPageFlyerWrapper>
      </FlyerPageContainer>
    </>
  );
};
