// Path: ./src/utils/LocationCheck.ts
import { Position } from '@capacitor/geolocation';
import { defaultPosition, getPosition } from 'utils/getUserLocation';
import { getIpAddress, getCityNameFromIp } from './getCityNameFromIP';

export const GetUserLocation = async function () {
  const storedLocation = localStorage.getItem('CustomerLocation');
  let position: Position;
  const isDev = window.config.REACT_APP_IS_DEV === `true`;

  if (storedLocation) {
    position = JSON.parse(storedLocation);
  } else {
    // Get current position if not stored
    position = await getPosition();
    updateStoredLocation(position);
  }

  if (isDefaultPosition(position) && !isDev) {
    // Retrieve updated location based on IP if in default position
    position = await updatePositionFromIP(position);
  }

  console.log('IP_Test_LOG position', position, defaultPosition); // will be removed in up-coming pr, kept for testing now
  return position;
};

function updateStoredLocation(position: Position) {
  const { longitude, latitude } = position.coords;
  const storedLocation = JSON.stringify({
    coords: {
      longitude: longitude?.changeDecimal(3),
      latitude: latitude?.changeDecimal(3),
    },
  });
  localStorage.setItem('CustomerLocation', storedLocation);
}

function isDefaultPosition(position: Position): boolean {
  return (
    position?.coords?.longitude?.toFixed(3) ===
      defaultPosition?.coords?.longitude?.toFixed(3) &&
    position?.coords?.latitude?.toFixed(3) ===
      defaultPosition?.coords?.latitude?.toFixed(3)
  );
}

async function updatePositionFromIP(
  currentPosition: Position,
): Promise<Position> {
  const ipAddress = await getIpAddress();
  if (ipAddress) {
    const coords = await getCityNameFromIp(ipAddress);
    if (coords) {
      return {
        ...currentPosition,
        coords: {
          ...currentPosition.coords,
          ...coords,
        },
      };
    }
  }
  return currentPosition; // Return unchanged if no new data is fetched
}

export const CheckUserLocationChange = async function () {
  try {
    const customerLocation = localStorage.getItem('CustomerLocation');
    const customerPosition: Position = JSON.parse(customerLocation as string);
    const customerPositionCheck = (await getPosition()) as Position;
    if (
      customerPositionCheck?.coords?.longitude.changeDecimal(3) !=
        customerPosition?.coords?.longitude.changeDecimal(3) ||
      customerPositionCheck?.coords?.longitude.changeDecimal(3) !=
        customerPosition?.coords?.longitude.changeDecimal(3)
    ) {
      localStorage.setItem(
        'CustomerLocation',
        JSON.stringify({
          coords: {
            longitude:
              customerPositionCheck?.coords?.longitude.changeDecimal(3),
            latitude: customerPositionCheck?.coords?.latitude.changeDecimal(3),
          },
        }),
      );
      window?.location?.reload();
    }
  } catch (error) {
    console.error(error);
  }
};
