// Path: ./src/app/pages/InfoPage/TopContent.tsx
/**
 *
 * TopInfoContent
 *
 */
import * as React from 'react';
import styled from 'styled-components/macro';
import { Link } from 'react-router-dom';

interface Props {
  Id: string;
  title: string;
  subtitle: string;
  image: string;
  buttonText: string;
  backgroudColor?: string;
  Link: string;
}

export function TopInfoContent(props: Props) {
  return (
    <TopContent
      bgColor={props.backgroudColor}
      data-testid="topinfocontent-test-id"
    >
      <Wrapper>
        <RightSide>
          <Title>{props.title} </Title>
          <SubTitle>{props.subtitle}</SubTitle>
          <CardButton data-testid="Start Saving Today" to={props.Link}>
            {props.buttonText}
          </CardButton>
        </RightSide>
        <LeftSide>
          <SideImageComponent src={props.image}></SideImageComponent>
        </LeftSide>
      </Wrapper>
    </TopContent>
  );
}

interface TopContentProps {
  bgColor?: string;
}

const CardButton = styled(Link)`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  background: #ef8200 0% 0% no-repeat padding-box;
  border-radius: 10px;
  opacity: 1;
  padding: 10px;
  font: normal normal bold 20px Arial;
  letter-spacing: 0px;
  color: #ffffff;
  width: 60%;
  margin-top: 40px;
  cursor: pointer;
  text-decoration: none;
  outline: none;
  transition: all 0.5s;
  @media (max-width: 1049px) {
    margin: auto;
    margin-bottom: 20px;
    margin-top: 20px;
  }
  @media (max-width: 707px) {
    font-size: 16px;
    margin: auto;
    margin-bottom: 20px;
    margin-top: 20px;
  }
  &:hover {
    background-color: #ffffff;
    border: 1px solid #ef8200;
    color: #ef8200;
  }
`;
const TopContent = styled.div`
  background-color: ${(props: TopContentProps) => {
    return props.bgColor ? props.bgColor : '#f3ece2';
  }};
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-top: 90px;
`;

const Wrapper = styled.div`
  max-width: 1400px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  @media (max-width: 1400px) {
    margin-left: 40px;
    margin-right: 40px;
  }
  @media (max-width: 1049px) {
    flex-direction: column;
  }
  @media (max-width: 471px) {
    margin-left: 10px;
    margin-right: 10px;
  }
`;
const RightSide = styled.div`
  width: 40%;
  display: flex;
  flex-direction: column;
  @media (max-width: 1049px) {
    width: 80%;
    margin-top: 60px;
  }
  @media (max-width: 471px) {
    width: 90%;
  }
`;

const LeftSide = styled.div`
  width: 50%;
  @media (max-width: 1400px) {
    width: 80%;
  }
  @media (max-width: 471px) {
    width: 90%;
  }
`;

const SideImageComponent = styled.img`
  width: 100%;
  object-fit: contain;
  object-position: bottom right;
  @media (max-width: 1049px) {
    object-fit: contain;
  }
`;

const Title = styled.div`
  text-align: left;
  font: normal normal bold 53px/61px Arial;
  letter-spacing: 0px;
  color: #707070;
  opacity: 1;
  @media (max-width: 1049px) {
    text-align: center;
    padding-right: 10px;
    padding-left: 10px;
  }
  @media (max-width: 707px) {
    font-size: 30px;
    line-height: 36px;
  }
`;

const SubTitle = styled.div`
  text-align: left;
  font: normal normal normal 24px/28px Arial;
  letter-spacing: 0px;
  color: #707070;
  margin-top: 40px;
  @media (max-width: 1049px) {
    text-align: center;
  }
  @media (max-width: 707px) {
    margin-top: 10px;
    font-size: 15px;
  }
`;
