// Path: ./src/app/components/MenuA/index.tsx
/**
 *
 * MenuA
 *
 */
import React, { useContext, useEffect, useState } from 'react';
import { styled as stlyedMUI } from '@mui/material/styles';

import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Badge } from '@mui/material';
import { useLocation } from 'react-router-dom';
import { findElementByIndex } from '../../../utils/arrayHelper';
import SearchIcon from '@mui/icons-material/Search';
import AssignmentTurnedInOutlinedIcon from '@mui/icons-material/AssignmentTurnedInOutlined';
import Typography from '@mui/material/Typography';
import { messages } from 'locales/messages';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { GoFlyerAppContext } from 'app/store/context';

// icons list: https://next.material-ui.com/components/material-icons/

interface Props {
  favPromotionNumber?: number;
}

// allowed paths to show and hide the bottom menu on scroll.
const allowedPaths = [
  '/flyer',
  '/search',
  '/promotionlist',
  '/latest',
  '/upcoming',
  '/favourites',
  '/flyers',
];

export function MenuA(props: Props) {
  const history = useHistory();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { t, i18n } = useTranslation();
  const location = useLocation();

  const routeArray = ['/flyers', '/mapstore', '/promotionfavourite'];
  const initMenuItemValue = findElementByIndex(routeArray, location.pathname);

  const [value, setValue] = useState(initMenuItemValue);
  const [deaksTabLink, setDeaksTabLink] = useState('/flyers');
  const [scrollDirection, setScrollDirection] = useState<'up' | 'down'>('up');
  const [prevScrollPos, setPrevScrollPos] = useState<number>(window.scrollY);
  const [isHideAllowed, setIsHideAllowed] = useState<boolean>(false);

  const {
    state: {
      customer: { gfShoppingList },
    },
  } = useContext(GoFlyerAppContext);

  const handleHideShowScroll = () => {
    const currentScrollPos = window.scrollY;

    if (currentScrollPos > prevScrollPos) {
      setScrollDirection('down');
    } else {
      setScrollDirection('up');
    }

    setPrevScrollPos(currentScrollPos);
  };

  useEffect(() => {
    return history.listen(location => {
      if (
        location.pathname === '/promotionlist' ||
        location.pathname === '/favourites' ||
        location.pathname === '/flyers'
      ) {
        setDeaksTabLink(location.pathname);
      }
    });
  }, [history]);

  useEffect(() => {
    const isAllowedPath = allowedPaths.some(
      path =>
        location.pathname === path || location.pathname.startsWith(path + '/'),
    );

    if (isAllowedPath) {
      setIsHideAllowed(true);
      window.addEventListener('scroll', handleHideShowScroll);

      return () => {
        window.removeEventListener('scroll', handleHideShowScroll);
      };
    } else {
      setIsHideAllowed(false);
    }

    return () => {
      setScrollDirection('up');
      setPrevScrollPos(0);
    };
  }, [location.pathname, prevScrollPos]);

  const bottomNavigationStyle = {
    transition: 'transform 0.3s ease-in-out',
    transform: isHideAllowed
      ? scrollDirection === 'down'
        ? 'translateY(100%)'
        : 'translateY(0)'
      : 'translateY(0)',
  };

  const favBaduger = props.favPromotionNumber ? (
    <Badge badgeContent={props.favPromotionNumber} max={99} color="error">
      <AssignmentTurnedInOutlinedIcon className={'faviconMenuA'} />
    </Badge>
  ) : (
    <AssignmentTurnedInOutlinedIcon className={'faviconMenuA'} />
  );
  return (
    <>
      {location.pathname.includes('/flyerview') ||
      location.pathname.includes('/restaurantview') ? (
        <></>
      ) : (
        <MuiBottomNavigation
          value={value}
          onChange={(event, newValue) => {
            setValue(newValue);
          }}
          showLabels
          className={` MenuA`}
          sx={{ ...bottomNavigationStyle }}
        >
          <MuiBottomNavigationAction
            data-testid="Deals menu"
            component={Link}
            to={{ pathname: deaksTabLink }}
            icon={<MuiLocalOfferIcon className={` ${'faviconMenuA'}`} />}
            label={
              <MuiTypography variant="caption" display="block" gutterBottom>
                {t(messages.Deals())}
              </MuiTypography>
            }
          />

          <MuiBottomNavigationAction
            data-testid="Nearby menu"
            component={Link}
            to={{ pathname: '/mapstore' }}
            icon={<LocationOnIcon className={'faviconMenuA'} />}
            label={
              <MuiTypography variant="caption" display="block" gutterBottom>
                {t(messages.Stores())}
              </MuiTypography>
            }
          />
          <MuiBottomNavigationAction
            data-testid={'Search menu'}
            component={Link}
            to={{ pathname: '/search' }}
            icon={<SearchIcon className={'faviconMenuA'} />}
            label={
              <MuiTypography variant="caption" display="block" gutterBottom>
                {t(messages.Search())}
              </MuiTypography>
            }
          />
          <MuiBottomNavigationAction
            data-testid={'Shoppinglist menu'}
            component={Link}
            to={{ pathname: '/shoppinglist' }}
            icon={favBaduger}
            label={
              <MuiTypography
                variant="caption"
                display="block"
                gutterBottom
                className={'MenuAShoppingList'}
              >
                {gfShoppingList.promotions.length ? (
                  <ShoppingListBadge lng={i18n.language}>
                    {gfShoppingList.promotions.length}
                  </ShoppingListBadge>
                ) : (
                  <></>
                )}
                {t(messages.Shopping_List())}
              </MuiTypography>
            }
          />
        </MuiBottomNavigation>
      )}
    </>
  );
}
type lengthPositionType = {
  lng: string;
};
const ShoppingListBadge = styled.div`
  color: white;
  background-color: #f58220;
  width: 20px;
  height: 20px;
  border-radius: 15px;
  position: absolute;
  top: 6px;
  left: 51px;
  /* top: -36px;
  left: ${(props: lengthPositionType) =>
    props.lng === 'zh' ? '18px' : '41px'}; */
  font-size: 14px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-block;
  text-align: center;
`;

const MuiBottomNavigation = stlyedMUI(BottomNavigation)(() => ({
  width: '100%',
  position: 'fixed',
  bottom: 0,
  height: 86,
  backgroundColor: 'white',
  borderTop: '0.5px solid #9999993d',
  zIndex: 999,
}));
const MuiLocalOfferIcon = stlyedMUI(LocalOfferIcon)(() => ({
  transform: 'scaleX(-1)',
  fontSize: '30px !important',
  maxHeight: '30px !important',
  maxWidth: '30px !important',
}));

const MuiTypography = stlyedMUI(Typography)(() => ({
  font: 'normal normal medium 10px/12px',
  fontFamily: 'SFProText',
  width: '110px',
  textAlign: 'center',
}));
const MuiBottomNavigationAction = stlyedMUI(BottomNavigationAction)<any>(
  ({ selected }) => ({
    selected: {
      color: '#007AFF !important',
    },
  }),
);
