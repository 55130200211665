// Path: ./src/app/components/NoSearchResult/index.tsx
/**
 *
 * NoSearchResult
 *
 */
import React, { memo } from 'react';
import styled from 'styled-components/macro';
import { useTranslation } from 'react-i18next';

interface Props {
  message?: string;
}

export const NoSearchResult: React.FunctionComponent<Props> = memo(props => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { t, i18n } = useTranslation();

  return (
    <Wrapper>
      <SearchDiv>
        <SearchLogo>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="38.892"
            height="39.229"
            viewBox="0 0 38.892 39.229"
          >
            <path
              id="SearchEmpty"
              d="M315.4-3494a3.163,3.163,0,0,1-1.09-.7l-8.277-8.291a15.717,15.717,0,0,1-3.893,1.8,14.865,14.865,0,0,1-4.405.65,15.371,15.371,0,0,1-6.108-1.219,15.811,15.811,0,0,1-5.016-3.387,15.9,15.9,0,0,1-3.387-5.018,15.328,15.328,0,0,1-1.223-6.108,15.312,15.312,0,0,1,1.223-6.1,15.918,15.918,0,0,1,3.387-5.019,15.819,15.819,0,0,1,5.016-3.387,15.375,15.375,0,0,1,6.108-1.219,15.355,15.355,0,0,1,6.1,1.219,15.865,15.865,0,0,1,5.015,3.387,15.9,15.9,0,0,1,3.39,5.019,15.327,15.327,0,0,1,1.222,6.1,14.956,14.956,0,0,1-.716,4.644,15.422,15.422,0,0,1-1.99,4.047l8.2,8.248a3.067,3.067,0,0,1,.7,1.058,3.371,3.371,0,0,1,.231,1.239,3.352,3.352,0,0,1-.415,1.661,3.08,3.08,0,0,1-1.145,1.175,3.221,3.221,0,0,1-1.668.43A3.518,3.518,0,0,1,315.4-3494Zm-21.978-32.506a11.186,11.186,0,0,0-3.541,2.39,11.168,11.168,0,0,0-2.389,3.541,10.828,10.828,0,0,0-.86,4.3,10.842,10.842,0,0,0,.86,4.311,11.088,11.088,0,0,0,2.389,3.534,11.335,11.335,0,0,0,3.541,2.389,10.755,10.755,0,0,0,4.312.867,10.757,10.757,0,0,0,4.311-.867,11.242,11.242,0,0,0,3.533-2.389,11.169,11.169,0,0,0,2.387-3.534,10.8,10.8,0,0,0,.864-4.311,10.784,10.784,0,0,0-.864-4.3,11.251,11.251,0,0,0-2.387-3.541,11.1,11.1,0,0,0-3.533-2.39,10.849,10.849,0,0,0-4.311-.859A10.847,10.847,0,0,0,293.422-3526.509Zm2.732,15.807a1.193,1.193,0,0,1,1.215-1.2,1.19,1.19,0,0,1,1.225,1.2,1.189,1.189,0,0,1-1.225,1.2A1.192,1.192,0,0,1,296.153-3510.7Zm.241-3.831v-.213a2.99,2.99,0,0,1,1.781-2.7c1.122-.677,1.521-1.234,1.521-2.143a1.877,1.877,0,0,0-2.078-1.846,2.066,2.066,0,0,0-2.161,1.568c-.186.575-.473.779-.938.779a.827.827,0,0,1-.9-.91,2.444,2.444,0,0,1,.25-1.085,3.93,3.93,0,0,1,3.831-2.059c2.422,0,4.1,1.419,4.1,3.469a3.509,3.509,0,0,1-1.948,3.155c-1.2.7-1.513,1.178-1.559,2.087-.047.594-.325.984-.928.984A.962.962,0,0,1,296.394-3514.533Z"
              transform="translate(-281.5 3532.5)"
              stroke="rgba(0,0,0,0)"
              stroke-width="1"
            />
          </svg>
        </SearchLogo>
        <Heading>{props.message}</Heading>
      </SearchDiv>
    </Wrapper>
  );
});

const Wrapper = styled.div`
  height: 286px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const SearchDiv = styled.div`
  text-align: center;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
`;
const SearchLogo = styled.div`
  background: #eeeef0 0% 0% no-repeat padding-box;
  opacity: 1;
  width: 87px;
  height: 87px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 55px;
`;
const Heading = styled.h2`
  text-align: center;
  width: 240px;
  margin: 0px;
  font: normal normal 17px/20px SFProDisplay;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
  margin-top: 15px;
  font-weight: 500;
`;
