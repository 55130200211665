// Path: ./src/app/desktop-src/app/pages/UpComingPage/index.tsx
import * as React from 'react';
import { GfFlyerDto } from '@swagger/typescript-fetch-goflyer';
import { FlyerCard } from 'app/desktop-src/app/components/FlyerCard/Loadable';
import { apiService } from 'utils/api';
import Grid from '@mui/material/Grid';
import InfiniteScroll from 'react-infinite-scroll-component';
import { RootState } from 'types';
import { useDispatch, useSelector } from 'react-redux';

import { checkIfStoreInFavList } from 'utils/dtoUtility';
import { useClickFavFunc } from 'app/desktop-src/app/common/ClickFavButtonFunc';
import { Spinner } from 'app/desktop-src/app/components/Spinner';
import { PageHeader } from 'app/desktop-src/app/components/PageHeader';
import { messages } from 'locales/messages';
import { useTranslation } from 'react-i18next';
import { useFlyerListSlice } from 'app/desktop-src/store/FlyerList';
import {
  FlyerPageContainer,
  FlyerPageFlyerLink,
  FlyerPageFlyerWrapper,
  FlyerPageNotFoundWrapper,
} from 'app/components/Common/FlyerPage';
import styled from 'styled-components/macro';
import Image1 from '../../../../assets/pic1.jpeg';
import Image2 from '../../../../assets/pic2.jpeg';
import Image3 from '../../../../assets/pic3.jpeg';
import Image4 from '../../../../assets/pic4.jpg';
import { Link } from 'react-router-dom';

import CustomHelmet from 'app/components/Helmet';

export const UpComingPage = () => {
  const { t } = useTranslation();
  const { actions } = useFlyerListSlice();
  const { upComing } = useSelector((state: RootState) => state.flyerList);
  const [hasMore, setHasMore] = React.useState<boolean>(true);
  const [pageLoader, setPageLoader] = React.useState<boolean>(true);

  const clickFavButton = useClickFavFunc();
  const dispatch = useDispatch();
  const { customer }: RootState = useSelector<RootState, RootState>(
    state => state,
  );

  React.useEffect(() => {
    setTimeout(() => {
      if (window !== undefined) {
        const scrolValue = upComing?.scrollPosition;
        if (scrolValue) {
          document
            ?.getElementById('scrollableDiv')
            // @ts-ignore
            ?.scrollTo({ top: scrolValue, behavior: 'auto' });
          // @ts-ignore
          window.scrollTo({ top: scrolValue });
        }
      }
    }, 50);
  }, []);

  const getFlyers = async () => {
    try {
      setHasMore(false);
      const limit = 20;
      const FlyerResultList = await apiService.gfFlyerControllerFindUpComing(
        // @ts-ignore
        upComing.currentPage,
        limit,
        JSON.stringify({
          orderBy: {
            validStartDate: 'ASC',
          },
        }),
        undefined,
      );
      console.log('FlyerResultList', FlyerResultList);
      if (FlyerResultList?.results?.length !== 0) {
        dispatch(
          actions.addFlyerList({
            type: 'upcoming',
            condition: 'PageNumer',
            // @ts-ignore
            data: upComing?.currentPage + 1,
          }),
        );
        setHasMore(true);
        const FlyerLatestResponse: GfFlyerDto[] =
          FlyerResultList?.results?.sort((a: GfFlyerDto, b: GfFlyerDto) => {
            if (a.stores[0]?.rank && !b.stores[0]?.rank) {
              return a.stores[0]?.rank;
            }
            if (b.stores[0]?.rank && !a.stores[0]?.rank) {
              return b.stores[0]?.rank;
            }
            if (b.stores[0]?.rank && a.stores[0]?.rank) {
              return a.stores[0]?.rank - b.stores[0]?.rank;
            }
            return 0;
          });
        // @ts-ignore
        if (upComing.results?.length) {
          dispatch(
            actions.addFlyerList({
              type: 'upcoming',
              data: {
                results: [
                  // @ts-ignore
                  ...(upComing.results as GfFlyerDto[]),
                  ...FlyerLatestResponse,
                ],
              },
            }),
          );
        } else {
          dispatch(
            actions.addFlyerList({
              type: 'upcoming',
              data: {
                results: [...FlyerLatestResponse],
              },
            }),
          );
        }
      }
      setPageLoader(false);
    } catch (error) {
      console.log('error', error);
    }
  };

  React.useEffect(() => {
    if (upComing?.results?.length) {
      getFlyers();
      setPageLoader(false);
    } else {
      getFlyers();
    }
  }, []);

  const handleScroll = event => {
    dispatch(
      actions.addFlyerList({
        type: 'upComing',
        condition: 'scrollPosition',
        data: event.currentTarget.scrollTop,
      }),
    );
  };

  const imagesList = [Image1, Image2, Image3, Image4];
  const isOdd = num => {
    return num % 2;
  };
  return (
    <>
      <CustomHelmet
        title={`Goflyer - ${t(messages.UpComing())}`}
        description={t(messages.upcomingDescription())}
        keywords={t(messages.upcomingKeywords())}
      />
      <FlyerPageContainer>
        <PageHeader data-testid="Flyers" title={t(messages.Flyers())} />

        {pageLoader ? (
          <Spinner />
        ) : (
          // @ts-ignore
          upComing.results === undefined ||
          (upComing && upComing?.results.length <= 0 && (
            <NotFound>
              <ImageWrapper>
                {imagesList?.map((val, index) => {
                  if (!isOdd(index + 1)) {
                    return (
                      <ImageElement2
                        src={val}
                        data-testid="upcomingpage-test-id"
                      />
                    );
                  }
                  return (
                    <ImageElement
                      src={val}
                      style={{
                        zIndex: index === 2 ? 9999999 : '1',
                        marginLeft: index === 2 ? '-50px' : '0',
                      }}
                      data-testid="upcomingpage-test-id"
                    />
                  );
                })}
              </ImageWrapper>
              <h1>{t(messages.THERE_ARE_NO_UPCOMING_FLYER_STAY_TUNED())}</h1>
              <UpComingButton
                to={{
                  pathname: `/flyers`,
                }}
              >
                {t(messages.CLICK_TO_SEE_MORE_FLYERS())}
              </UpComingButton>
            </NotFound>
          ))
        )}

        <FlyerPageFlyerWrapper id="scrollableDiv" onScroll={handleScroll}>
          <InfiniteScroll
            scrollThreshold={0.2}
            style={{
              paddingBottom: '50px',
              paddingLeft: '40px',
              paddingRight: '40px',
            }}
            next={getFlyers}
            // @ts-ignore
            dataLength={upComing.results?.length ? upComing.results.length : 20}
            hasMore={hasMore}
            loader={<Spinner />}
            scrollableTarget="scrollableDiv"
          >
            <Grid container spacing={2} data-testid="Flyer list Wrapper">
              {upComing?.results?.map((value: GfFlyerDto, key) => {
                const store_in_fav_list = checkIfStoreInFavList(
                  value?.stores[0],
                  customer?.gfStoreFavouriteList,
                );
                return (
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    lg={6}
                    key={key}
                    data-testid="upcomingpage-test-id"
                  >
                    <FlyerPageFlyerLink
                      to={{
                        pathname: `/flyerview/${
                          value.id
                        }-${value.stores[0].merchant.name
                          .replace(/\s+/g, '-')
                          .toLowerCase()}-flyer`,
                        state: {
                          FlyerDetails: JSON.stringify(value),
                        },
                      }}
                    >
                      <FlyerCard
                        data={value}
                        storeFavList={store_in_fav_list}
                        handleHeartClick={e => {
                          clickFavButton(
                            e,
                            value?.stores[0],
                            store_in_fav_list !== undefined,
                          );
                        }}
                      />
                    </FlyerPageFlyerLink>
                  </Grid>
                );
              })}
            </Grid>
          </InfiniteScroll>
        </FlyerPageFlyerWrapper>
      </FlyerPageContainer>
    </>
  );
};

const UpComingButton = styled(Link)`
  width: 429px;
  padding: 14px;
  background: #a3eaf3 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 42px;
  font: normal normal bold 21px/25px Arial;
  letter-spacing: 0px;
  color: #000000;
  text-transform: uppercase;
  cursor: pointer;
`;

const NotFound = styled(FlyerPageNotFoundWrapper)`
  flex-direction: column;
`;

const ImageWrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
`;

const ImageElement = styled.img`
  position: relative;
  width: 206px;
  height: 243px;
  object-fit: cover;
`;

const ImageElement2 = styled.img`
  position: relative;
  z-index: 1;
  width: 206px;
  height: 243px;
  margin-top: -60px;
  margin-left: -50px;
  object-fit: cover;
`;
