// Path: ./src/app/desktop-src/app/pages/InfoPage/CommanContent.tsx
/**
 *
 * CommonInfoContent
 *
 */
import * as React from 'react';
import styled from 'styled-components/macro';
import { Link } from 'react-router-dom';

interface Props {
  Id: string;
  title: string;
  subtitle: string;
  image: string;
  buttonText: string;
  backgroudColor?: string;
  rightSide?: boolean;
  Link: string;
  video?: any;
}

export function CommonInfoContent(props: Props) {
  React.useEffect(() => {
    setTimeout(() => {
      if (props.Id && props.video) {
        const vid: any = document.getElementById(
          props?.Id ? props.Id : 'FlyerFlipVideo',
        );

        if (vid) {
          const promise = vid?.play();
          if (promise !== undefined) {
            promise
              .then(_ => {})
              .catch(error => {
                // Autoplay not allowed!
                // Mute video and try to play again
                vid.muted = true;
                vid.play();
              });
          }
        }
      }
    }, 500);
  }, []);

  return (
    <TopContent
      bgColor={props.backgroudColor}
      data-testid="commoninfocontent-test-id"
    >
      {props.rightSide ? (
        <Wrapper rightSide={props.rightSide}>
          <RightSide rightSide={props.rightSide}>
            <Title rightSide={props.rightSide}>
              {props.title.includes('\n')
                ? props.title.split('\n').map(str => <div>{str}</div>)
                : props.title}{' '}
            </Title>
            <SubTitle rightSide={props.rightSide}>
              {props.subtitle.includes('\n')
                ? props.subtitle.split('\n').map(str => <div>{str}</div>)
                : props.subtitle}{' '}
            </SubTitle>
            <CardButton to={props.Link}>{props.buttonText}</CardButton>
          </RightSide>
          <LeftSide>
            {props.video ? (
              <VideoWrapper>
                <CustomeVideo id={props.Id} loop autoplay muted playsinline>
                  <source src={props?.video} type="video/mp4" />
                </CustomeVideo>
              </VideoWrapper>
            ) : (
              <SideImageComponent
                src={props.image}
                rightSide={props.rightSide}
              ></SideImageComponent>
            )}
          </LeftSide>
        </Wrapper>
      ) : (
        <Wrapper rightSide={props.rightSide}>
          <LeftSide>
            {props.video ? (
              <VideoWrapper>
                <CustomeVideo id={props.Id} loop autoplay muted playsinline>
                  <source src={props?.video} type="video/mp4" />
                </CustomeVideo>
              </VideoWrapper>
            ) : (
              <SideImageComponent
                src={props.image}
                rightSide={props.rightSide}
              ></SideImageComponent>
            )}
          </LeftSide>

          <RightSide rightSide={props.rightSide}>
            <Title rightSide={props.rightSide}>
              {' '}
              {props.title.includes('\n')
                ? props.title.split('\n').map(str => <div>{str}</div>)
                : props.title}{' '}
            </Title>
            <SubTitle rightSide={props.rightSide}>
              {' '}
              {props.subtitle.includes('\n')
                ? props.subtitle.split('\n').map(str => <div>{str}</div>)
                : props.subtitle}{' '}
            </SubTitle>
            <CardButton to={props.Link}>{props.buttonText}</CardButton>
          </RightSide>
        </Wrapper>
      )}
    </TopContent>
  );
}

interface StyleProps {
  bgColor?: string;
  rightSide?: boolean;
}

const CustomeVideo: any = styled.video`
  max-height: 700px;
  display: flex;
  justify-content: center;
  align-items: center;
  @media (max-width: 471px) {
    max-width: 471px;
  }
`;
const VideoWrapper = styled.div`
  display: flex;
  justify-content: start;
  align-items: center;
  @media (max-width: 1049px) {
    justify-content: center;
    align-items: center;
  }
`;
const CardButton = styled(Link)`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  background: #ef8200 0% 0% no-repeat padding-box;
  border-radius: 10px;
  opacity: 1;
  padding: 10px;
  font: normal normal bold 20px Arial;
  letter-spacing: 0px;
  color: #ffffff;
  width: 60%;
  margin-top: 40px;
  cursor: pointer;
  text-decoration: none;
  outline: none;
  transition: all 0.5s;
  @media (max-width: 1049px) {
    margin: auto;
    margin-bottom: 20px;
    margin-top: 20px;
  }
  @media (max-width: 707px) {
    font-size: 16px;
    margin: auto;
    margin-bottom: 20px;
    margin-top: 20px;
  }
  &:hover {
    background-color: #ffffff;
    border: 1px solid #ef8200;
    color: #ef8200;
  }
`;
const TopContent = styled.div`
  background-color: ${(props: StyleProps) => {
    return props.bgColor ? props.bgColor : '#f3ece2';
  }};
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 700px;
  @media (max-width: 1400px) {
    height: 100%;
  }
`;

const Wrapper = styled.div`
  max-width: 1400px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  @media (max-width: 1400px) {
    margin-left: 40px;
    margin-right: 40px;
    padding-top: 40px;
    padding-bottom: 40px;
  }
  @media (max-width: 1049px) {
    flex-direction: ${(props: StyleProps) => {
      return props.rightSide ? 'column' : 'column-reverse';
    }};
    padding-top: 0px;
    padding-bottom: 0px;
  }
  @media (max-width: 471px) {
    margin-left: 10px;
    margin-right: 10px;
  }
`;
const RightSide = styled.div`
  width: 40%;
  min-width: 40%;
  display: flex;
  flex-direction: column;
  align-items: ${(props: StyleProps) => {
    return props.rightSide ? 'start' : 'end';
  }};
  @media (max-width: 1049px) {
    width: 80%;
    margin-top: 60px;
    align-items: center;
  }
  @media (max-width: 471px) {
    max-width: 90%;
    align-items: center;
  }
`;

const LeftSide = styled.div`
  width: 50%;
  @media (max-width: 1400px) {
    width: 50%;
  }
  @media (max-width: 471px) {
    max-width: 90%;
  }
`;

const SideImageComponent = styled.img`
  width: 100%;
  max-height: 500px;
  object-fit: contain;
  object-position: ${(props: StyleProps) => {
    return props.rightSide ? 'right' : 'left';
  }};
  @media (max-width: 1049px) {
    object-fit: contain;
    object-position: center;
    padding-top: 20px;
    padding-bottom: 20px;
  }
`;

const Title = styled.div`
  text-align: ${(props: StyleProps) => {
    return props.rightSide ? 'left' : 'right';
  }};
  font: normal normal bold 44px/61px Arial;
  letter-spacing: 0px;
  color: #707070;
  opacity: 1;
  @media (max-width: 1049px) {
    text-align: center;
  }
  @media (max-width: 707px) {
    font-size: 30px;
    line-height: 36px;
    text-align: center;
    margin: auto;
  }
`;

const SubTitle = styled.div`
  text-align: ${(props: StyleProps) => {
    return props.rightSide ? 'left' : 'right';
  }};
  font: normal normal normal 24px/28px Arial;
  letter-spacing: 0px;
  color: #707070;
  margin-top: 40px;
  @media (max-width: 1049px) {
    text-align: center;
  }
  @media (max-width: 707px) {
    margin-top: 10px;
    font-size: 15px;
  }
`;
