// Path: ./src/app/pages/MobileLayout/index.tsx
/**
 *
 * MobileLayout
 *
 */
import { HeaderA } from 'app/components/HeaderA';
import { MenuA } from 'app/components/MenuA';
import { ModalSubscription } from 'app/components/ModalSubscription';
import { ShoppingListViewModal } from 'app/components/ShoppingListViewModal';
import { SideNavBar } from 'app/components/SideNavBar';
import { GoFlyerAppContext } from 'app/store/context';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components/macro';
import { apiService } from 'utils/api';
import { FlyerTagListId } from 'utils/FlyerTagListId';
import { localstorageGet, localstorageSet } from 'utils/localstorage';
import { App as CapacitorApp } from '@capacitor/app';
import { Device, DeviceInfo } from '@capacitor/device';
import { SplashScreen } from '@capacitor/splash-screen';
import * as analytics from 'utils/analytics';
import CacheRequest from '../../mobile-desktop-common-components/CacheRequest';
import { GetUserLocation } from 'utils/LocationCheck';
import { Position } from '@capacitor/geolocation';

interface Props {}

export const MobileLayout: React.FunctionComponent<Props> = props => {
  const { i18n } = useTranslation();
  const { location, replace } = useHistory();
  let capDataUpdate = React.useRef<any>();
  const language = location.search.split('=');
  const { state } = React.useContext(GoFlyerAppContext);
  const [totalTagsType, setTotalTagsType] = React.useState<{
    grocery: number;
    restaurant: number;
    chinesesupermarket: number;
    electricalAppliances: number;
    totalFlyers: number;
    upComing: number;
    favCount: number;
    deviceInfo: DeviceInfo | undefined;
  }>({
    grocery: 0,
    restaurant: 0,
    chinesesupermarket: 0,
    electricalAppliances: 0,
    totalFlyers: 0,
    upComing: 0,
    favCount: 0,
    deviceInfo: undefined,
  });

  const detectAndChangeLanguage = () => {
    const localLang = localStorage.getItem('Lang');
    switch (language[1]?.toLocaleLowerCase()) {
      case 'chinese':
        if (localLang === 'zh') {
        } else {
          localstorageSet('Lang', 'zh');
          i18n.changeLanguage('zh');
          replace({ state: 'chinese' });
        }
        break;
      case 'english':
        if (localLang === 'en') {
        } else {
          localstorageSet('Lang', 'en');
          i18n.changeLanguage('en');
          replace({ state: 'english' });
        }
        break;
      default:
        if (localLang) {
        } else {
          localstorageSet('Lang', 'en');
          i18n.changeLanguage('en');
          replace({ state: 'english' });
        }
        break;
    }
  };

  const reFetchShoppingList = async () => {
    const customer: any = localstorageGet('customer');
    if (customer?.gfShoppingList?.id) {
      const result =
        await apiService.gfShoppingListControllerFindOneShoppingListWithFlyerItemsAndPromotions(
          customer.gfShoppingList.id,
        );
      if (result) {
        localstorageSet('customer', {
          ...customer,
          gfShoppingList: result,
        });
      }
    }
  };

  const ClearSearchedValue = () => {
    if (
      location.pathname === '/search' ||
      location.pathname.includes('/flyerview/') ||
      location.pathname.includes('/promotiondetail') ||
      location.pathname.includes('/storedetails')
    ) {
    } else {
      localStorage.setItem('SearchedValue', '');
    }
  };

  React.useEffect(() => {
    reFetchShoppingList();
  }, []);

  React.useEffect(() => {
    ClearSearchedValue();
  }, [location.pathname]);

  React.useEffect(() => {
    reFetchShoppingList();
    detectAndChangeLanguage();
  }, [location.pathname]);

  React.useEffect(() => {
    backgroundCheck();
  }, []);

  const backgroundCheck = async () => {
    try {
      const info = await Device.getInfo();
      if ((info && info.platform === 'android') || info.platform === 'ios') {
        analytics.platform(info.platform);
        CapacitorApp.addListener('appStateChange', async ({ isActive }) => {
          if (isActive === true) {
            getFlyersCount();
            try {
              // const response =
              //   await apiService.gfVersionControlControllerFindOne('latest');
              // console.log(`CapacitorUpdater response`, response);
              // const result = await response.json();
              // console.log(`CapacitorUpdater result`, result);
              // const versionNumber = result.version;
              // console.log(`CapacitorUpdater versionNumber`, versionNumber);
              // // Do the download during user active app time to prevent failed download
              // console.log(`CapacitorUpdater updateResult`, updateResult);
              // capDataUpdate.current = updateResult;
            } catch (error) {
              console.log('CapacitorUpdater download issue', error);
            }
          }
          if (!isActive) {
            if (capDataUpdate?.current?.id) {
              // Do the switch when user leave app
              SplashScreen.show();
              try {
                // await CapacitorUpdater.set({ id: capDataUpdate?.current.id });
              } catch (err) {
                console.log(err);
                SplashScreen.hide(); // in case the set fail, otherwise the new app will have to hide it
              }
            }
          }
        });
      }
    } catch {}
  };

  //TODO: move this to backend
  const getFlyersCount = async () => {
    const deviceInfo = await Device.getInfo();
    const position = (await GetUserLocation()) as Position;

    const flyerCount = await apiService.gfFlyerControllerCountFlyerByTag(
      position.coords.longitude.changeDecimal(3),
      position.coords.latitude.changeDecimal(3),
      undefined,
    );
    const groceryCount = await apiService.gfFlyerControllerCountFlyerByTag(
      position.coords.longitude.changeDecimal(3),
      position.coords.latitude.changeDecimal(3),
      FlyerTagListId.Grocery.Id,
    );
    const flyerRestaurant = await apiService.gfMenuControllerCountMenuByTag(
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      FlyerTagListId.Restaurant.Id,
    );

    const resultFavCount =
      await apiService.gfStoreFavouriteListControllerCountFavList(
        state?.customer?.gfStoreFavouriteList?.id,
      );

    const chinesSuperMarket = await apiService.gfFlyerControllerCountFlyerByTag(
      position.coords.longitude.changeDecimal(3),
      position.coords.latitude.changeDecimal(3),
      FlyerTagListId.ChineseSuperMarket.Id,
    );
    const electricalAppliances =
      await apiService.gfFlyerControllerCountFlyerByTag(
        position.coords.longitude.changeDecimal(3),
        position.coords.latitude.changeDecimal(3),
        FlyerTagListId.ElectricalAppliances.Id,
      );
    const upComingFlyer = await apiService.gfFlyerControllerCountFlyerByTag(
      position.coords.longitude.changeDecimal(3),
      position.coords.latitude.changeDecimal(3),
      FlyerTagListId.upComingFlyer.Id,
    );
    setTotalTagsType({
      grocery: groceryCount?.total,
      chinesesupermarket: chinesSuperMarket?.total,
      electricalAppliances: electricalAppliances?.total,
      restaurant: flyerRestaurant?.total,
      totalFlyers: flyerCount?.total,
      upComing: upComingFlyer?.total,
      favCount: resultFavCount?.total,
      deviceInfo,
    });
  };

  React.useEffect(() => {
    getFlyersCount();
  }, [state.menuSideBar]); //TODO: if you do this, there will be many call to the backend and will have lots of aws cost. you listen to menuSideBar only because of you want to update the fav count. other count won't change in the same session. so use 2 useEffect here

  return (
    <Div data-testid="mobilelayout-test-id">
      {state.menuSideBar ? (
        <SideNavBar
          deviceInfo={totalTagsType.deviceInfo}
          totalTagsType={totalTagsType}
        />
      ) : (
        <></>
      )}
      <HeaderA></HeaderA>
      <ModalSubscription></ModalSubscription>
      <ShoppingListViewModal />
      {props.children}
      <div id="buttommenu-id">
        <MenuA
        // favPromotionNumber={state.promotionFavouriteList.promotions.length}
        ></MenuA>
      </div>
      <CacheRequest></CacheRequest>
    </Div>
  );
};

const Div = styled.div``;
