// Path: ./src/app/components/NoResultsReturn/index.tsx
/**
 *
 * NoStoreSetFavorite
 *
 */
import React, { memo } from 'react';
import { styled as stlyedMUI } from '@mui/material/styles';

import styled from 'styled-components/macro';
import FavoriteIcon from '@mui/icons-material/Favorite';

interface Props {
  content: JSX.Element;
}

const MuiFavoriteIcon = stlyedMUI(FavoriteIcon)(() => ({
  fontSize: '40px',
  color: '#FF453A',
}));

export const NoResultsReturn: React.FunctionComponent<Props> = memo(
  (props: Props) => {
    return (
      <div>
        <SecondDiv>
          <HeartDiv>
            <MuiFavoriteIcon />
          </HeartDiv>
          <Title>{props.content}</Title>
        </SecondDiv>
      </div>
    );
  },
);

const SecondDiv = styled.div`
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  max-width: 240px;
  margin: auto;
  height: 80vh;
`;

const Title = styled.h2`
  font: normal normal medium 17px/20px;
  font-family: SFProDisplay;
  font-size: 17px;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
`;

const HeartDiv = styled.div`
  height: 87px;
  width: 87px;
  background: #eeeef0 0% 0% no-repeat padding-box;
  opacity: 1;
  border-radius: 59px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
