// Path: ./src/app/pages/NewStoreUi/index.tsx
/**
 *
 * ExpandableDiv
 *
 */
import React, { memo } from 'react';
import styled from 'styled-components/macro';
import { useTranslation } from 'react-i18next';
import { GfPromotionDto, GfStoreDto } from '@swagger/typescript-fetch-goflyer';
import { apiService } from '../../../utils/api';
import { MapA } from 'app/components/MapA';
import { Spinner } from 'app/components/Spinner';
import { Link, useParams } from 'react-router-dom';
import { GoFlyerAppContext } from 'app/store/context';
import { Box } from '@mui/material';
import {
  getMyDistanceToStore,
  storeOpenNow,
  checkIfPromotionInShoppingList,
  getFlyerStatusString,
  getSmallImageIfExist,
  getDaysLeftOfPromotion,
} from 'utils/dtoUtility';
import { StoreUiFlyer } from 'app/components/StoreUiFlyer';
import { checkIfStoreInFavList } from 'utils/dtoUtility';
import ReactGA from 'react-ga';
import { GetQuery } from 'utils/getQueryPara';
import { PaginatedResponseOfGfPromotionDto } from '@swagger/typescript-fetch-goflyer';
import moment from 'moment';
import { BestDealSubComponent } from 'app/components/BestDealSubComponent';
import { NewStoreUiMoveAbleComp } from 'app/components/NewStoreUiMoveAbleComp';
import { messages } from 'locales/messages';
import * as analytics from '../../../utils/analytics';
import { useClickFavMobileFunc } from 'utils/commonFunctions/ClickFavButtonMobile';
import { useAddRemoveShoppinListMobileFunc } from 'utils/commonFunctions/AddRemoveShoppingListMobile';
import CustomHelmet from 'app/components/Helmet';
import { IParams } from '../FlyerViewMenu';
import { BestDealCard } from 'app/components/BestDealCard';

interface Props {}

export const NewStoreUi: React.FunctionComponent<Props> = memo(() => {
  const { state } = React.useContext(GoFlyerAppContext);
  const Params = useParams<IParams>();
  /**
   * we keep this as a legacy since we have some url on google that looks like: http://localhost:3001/storedetails?storeId=77f8d3e6-a0c2-490f-8d47-dbefe72b2121 so when you use storeId, use the new path: http://localhost:3001/storedetails/77f8d3e6-a0c2-490f-8d47-dbefe72b2121
   */
  let storeId = GetQuery('storeId') as string;
  if (!storeId && Params.id) {
    storeId = Params.id;
  }

  const { t } = useTranslation();
  // const [stores, setStores] = React.useState<GfStoreDto[]>([]);
  const [center, setCenter] = React.useState<any>();
  // const [index, setIndex] = React.useState<number>();

  const [store, setStore] = React.useState<GfStoreDto | undefined>();
  const [promotions, setPromotions] = React.useState<
    PaginatedResponseOfGfPromotionDto | undefined
  >();
  const [flyerItemList, setFlyerItemList] = React.useState<GfPromotionDto[]>(
    [],
  );

  React.useEffect(() => {
    initData();
    const _center = {
      lat: state.location.coords.latitude,
      lng: state.location.coords.longitude,
    };
    setCenter(_center);
  }, []);
  // console.log('cords', state.location.coords);
  React.useEffect(() => {
    // i18n.changeLanguage(`zh`);
    getStore();
  }, []);
  const initData = async function () {};

  async function onClickMarker(e, store) {
    ReactGA.event({
      category: '1.2',
      action: `Click ${store.merchant.name} Marker`,
    });
  }

  const getStore = async () => {
    const store = await apiService.gfStoreControllerFindOne(storeId);
    setStore(store);
    analytics.view_store_detail_page(store);
    const promotions = await apiService.gfStoreControllerFindPromotionsByStore(
      storeId,
    );

    setPromotions(promotions);
    const responseItems = await apiService.gfFlyerItemControllerFindAllByStore(
      storeId,
    );
    if (responseItems?.length) {
      const flyerItemsList = responseItems.map(value => {
        //TODO: this is a very bad mapping
        return {
          id: value.id,
          quantity: value.limitQuantityPerFamily, // Make sure this is nullable if it's optional, we need to create another card instead of re-using BestDealSubComponent
          price: value.salePrice,
          unit: value.unit,
          isExclusive: value.isExclusive,
          rank: value.rank,
          validStartDate: value.flyerItemList.validStartDate,
          validEndDate: value.flyerItemList.validEndDate,
          limitQuantityPerFamily: value.limitQuantityPerFamily,
          createdAt: value.createdAt,
          placement: {
            id: value.id,
            createdAt: value.createdAt,
            isDeleted: value.isDeleted,
            price: value.regularPrice,
            product: {
              available: null,
              compareAtPrice: null,
              createdAt: value.createdAt,
              description: value.priceRemarks,
              descriptionChinese: value.priceRemarks,
              gfImages: value.gfImages,
              id: value.id,
              isDeleted: value.isDeleted,
              name: value.name,
              nameChinese: value.nameChinese,
              publishedAt: null,
              updatedAt: value.updatedAt,
              store: value.flyerItemList.stores?.length
                ? value.flyerItemList.stores[0]
                : [],
            },
            store: value.flyerItemList.stores?.length
              ? value.flyerItemList.stores[0]
              : [],
            updatedAt: value.updatedAt,
          } as any,
          publishAt: value.flyerItemList.validStartDate,
          showAt: value.showAt,
          updatedAt: value.updatedAt,
          isDeleted: value.isDeleted,
        } as GfPromotionDto;
      });
      setFlyerItemList(flyerItemsList as GfPromotionDto[]);
    }
  };
  const clickFavButton = useClickFavMobileFunc(undefined);
  const clickAddToShoppingListButton = useAddRemoveShoppinListMobileFunc();

  if (store === undefined) {
    return <Spinner></Spinner>;
  }

  const StoreFlyerCards = store.gfFlyers?.map((value, key) => {
    return (
      <Link
        data-testid="New Store UI Flyer View Link"
        to={`/flyerview/${value.id}`}
      >
        <StoreUiFlyer
          key={key}
          RightImage={getSmallImageIfExist(
            value?.gfImages?.[0],
            state.remoteConfig.getSmallImagesCondition,
          )}
          LeftImage={getSmallImageIfExist(
            value?.gfImages?.[1],
            state.remoteConfig.getSmallImagesCondition,
          )}
          daysLeft={getFlyerStatusString(value)}
          price={value.totalSave}
        />
      </Link>
    );
  });

  const StoreMenusCards = store?.gfMenus?.map((value, key) => {
    return (
      <Link
        data-testid="New Store UI Flyer View Link"
        to={`/restaurantview/${value.id}`}
      >
        <StoreUiFlyer
          key={key}
          RightImage={getSmallImageIfExist(
            value?.gfImages?.[0],
            state.remoteConfig.getSmallImagesCondition,
          )}
          LeftImage={getSmallImageIfExist(
            value?.gfImages?.[1],
            state.remoteConfig.getSmallImagesCondition,
          )}
        />
      </Link>
    );
  });

  const PromotionDealsCard = promotions?.results.map((value, key) => {
    const promotion_in_shopping_list = checkIfPromotionInShoppingList(
      value,
      state.customer.gfShoppingList,
    );

    return (
      <Link
        data-testid="New Store UI promotion detail Link"
        key={key}
        to={`/promotiondetail?promotionId=${value.id}`}
      >
        {value.placement.store.id === storeId ? (
          <PromotionDiv key={key}>
            <BestDealSubComponent
              startingDate={value.validStartDate}
              data-testid="new store ui add to shopping list button"
              Image={value.placement.product.gfImages?.[0]?.src}
              productName={value.placement.product.name}
              promotionShoppingList={promotion_in_shopping_list}
              discountPrice={value.price}
              quantity={value.quantity}
              unit={value.unit}
              price={value.placement?.price}
              onClick={e => {
                clickAddToShoppingListButton(
                  e,
                  value,
                  promotion_in_shopping_list !== undefined,
                );
              }}
            />
          </PromotionDiv>
        ) : (
          <BestDealCard
            data-testid="new store ui add to shopping list button"
            validStartDate={value.validStartDate}
            Logo={
              value.placement.store.merchant.merchantMapLargeLogo
                ? value.placement.store.merchant.merchantMapLargeLogo.src
                : value.placement.store.merchant.merchantLogo.src
            }
            distance={getMyDistanceToStore(
              value.placement.store,
              state.location,
            )}
            name={value.placement.store.merchant.name}
            storeId={value.placement.store.id}
            description={value.placement.store.merchant.description}
            productName={value.placement.product.name}
            RightImage={value.placement.product.gfImages?.[0]?.src}
            price={value.placement?.price}
            unit={value.unit}
            quantity={value.quantity}
            discountPrice={value.price}
            daysLeft={getDaysLeftOfPromotion(value)}
            promotionShoppingList={promotion_in_shopping_list}
            onClickShoppingListButton={e => {
              clickAddToShoppingListButton(
                e,
                value,
                promotion_in_shopping_list !== undefined,
              );
            }}
          />
        )}
      </Link>
    );
  });

  const FlyerItemsCardsList = flyerItemList?.map((value, key) => {
    const promotion_in_shopping_list = checkIfPromotionInShoppingList(
      value,
      state.customer.gfShoppingList,
    );
    return (
      <Link
        data-testid="New Store UI promotion detail Link"
        key={key}
        to={`/promotiondetail?promotionId=${value.id}`}
      >
        <PromotionDiv key={key}>
          <BestDealSubComponent
            startingDate={value.validStartDate}
            data-testid="new store ui add to shopping list button"
            Image={value.placement.product.gfImages?.[0]?.src}
            productName={value.placement.product.name}
            promotionShoppingList={promotion_in_shopping_list}
            discountPrice={value.price}
            quantity={value.quantity}
            unit={value.unit}
            price={value.placement?.price}
          />
        </PromotionDiv>
      </Link>
    );
  });

  const store_in_fav_list = checkIfStoreInFavList(
    store,
    state.customer.gfStoreFavouriteList,
  );
  return (
    <Wrapper>
      <Div>
        <Box id="MapStorePage">
          <CustomHelmet
            title={`Goflyer - ${store.merchant.name}`}
            description={t(messages.homeDescription())}
            keywords={t(messages.homeKeywords())}
          />

          <Box>
            <Box
              position="absolute"
              top={'calc(env(safe-area-inset-top) + 110px)'}
              width={'100%'}
            >
              <MapContainer>
                <MapA
                  onClickMarker={(e, store) => onClickMarker(e, store)}
                  center={center}
                  stores={[store]}
                  useSelectedMarker={true}
                  storeDetails={true}
                ></MapA>
              </MapContainer>
            </Box>
          </Box>
        </Box>
        <NewStoreUiMoveAbleComp
          openStatus={
            storeOpenNow(store) ? t(messages.Open()) : t(messages.Closed())
          }
          Logo={store.merchant.merchantMapLargeLogo?.src}
          address={store.address}
          googleMapUrl={store.googleMapUrl}
          PhoneNumber={store.phone as string}
          Name={store.merchant.name}
          Description={store.merchant.description}
          onClickFav={e => {
            clickFavButton(e, store, store_in_fav_list !== undefined);
          }}
          StoreInFavList={store_in_fav_list}
          GoogleStar={store.googleStar}
          Distance={getMyDistanceToStore(store, state.location)?.toFixed(1)}
          OpenTime={moment(store.openTime, 'hh').format('LT')}
          CloseTime={moment(store.closeTime, 'hh').format('LT')}
          StoreFlyerCardList={
            StoreMenusCards?.length ? StoreMenusCards : StoreFlyerCards
          }
          StorePromotionCardList={[
            ...(PromotionDealsCard ?? []),
            ...FlyerItemsCardsList,
          ]}
        />
      </Div>
    </Wrapper>
  );
});

const Wrapper = styled.div`
  height: 91vh;
  overflow: hidden;
`;
const MapContainer = styled.div`
  height: 55vh;
`;
const PromotionDiv = styled.div`
  background: #ffffff 0% 0% no-repeat padding-box;
  border-radius: 20px;
  opacity: 1;
  box-shadow: 0px 3px 12px #00000040;
  margin: auto;
  max-width: 365px;
  margin-top: 21px;
`;

const Div = styled.div`
  /* overflow: hidden; */ // for fixing storedetails if enable, card will not show
`;
