// Path: ./src/utils/commonFunctions/AddRemoveShoppingListMobile.tsx
import { GfPromotionDto } from '@swagger/typescript-fetch-goflyer';
import { GoFlyerAppContext } from 'app/store/context';
import { setCustomer } from 'app/store/reducer';
import React from 'react';
import { apiService } from 'utils/api';
import ReactGA from 'react-ga';
import * as analytics from '../analytics';

export const useAddRemoveShoppinListMobileFunc = () => {
  const { state, dispatch } = React.useContext(GoFlyerAppContext);

  const clickRemoveFromShoppingListButton = async (
    e,
    promotion: GfPromotionDto,
    showCheckButton,
  ) => {
    try {
      // await apiService.gfStoreFavouriteListControllerAddStore
      ReactGA.event({
        category: '1.2',
        action: `Click  Remove  Button`,
      });
      e.preventDefault();
      if (showCheckButton) {
        const customer = state.customer;
        customer.gfShoppingList = {
          ...customer.gfShoppingList,
          promotions: customer.gfShoppingList.promotions?.filter(
            val => val.id != promotion.id,
          ),
        };
        await apiService.gfShoppingListControllerRemovePromotion(
          {
            promotionId: promotion.id,
          },
          state.customer.gfShoppingList.id,
        );
        /**
         * I move the dispatch after the remove because it is possible fetch the shopping list before the remove promotion.
         * TODO: but this need to be improved. because if network is slow, when customers click the delete button, it will take time to remove the shopping list item. either add a loader or change the loading shopping list logic
         */
        dispatch(setCustomer(customer));
        analytics.click_remove_deal_from_shopping_list(promotion);
      } else {
        const customer = state.customer;
        customer.gfShoppingList = {
          ...customer.gfShoppingList,
          promotions: [...customer.gfShoppingList.promotions, promotion],
        };
        await apiService.gfShoppingListControllerAddPromotion(
          {
            promotionId: promotion.id,
          },
          state.customer.gfShoppingList.id,
        );
        dispatch(setCustomer(customer));
        analytics.click_add_to_shopping_list_button(promotion);
      }
    } catch (error) {
      console.error(error);
      const customer = state.customer;
      customer.gfShoppingList = {
        ...customer.gfShoppingList,
        promotions: customer.gfShoppingList.promotions?.filter(
          val => val.id !== promotion.id,
        ),
      };
      dispatch(setCustomer(customer));
    }
  };

  return clickRemoveFromShoppingListButton;
};
